<template>
  <div class="card-container">
    <Card>
      <p slot="title">基本信息</p>
      <div class="card-box clearfix">
        <Row>
          <Col :lg="{ span: 2 }" :md="{ span: 4 }" class="tac" v-if="infoId">
            <Upload
              ref="upload"
              :format="['jpg', 'jpeg', 'png']"
              action="/api/member/photo/upload"
              :before-upload="handleBeforeUpload"
              :on-success="handleUploadSuccess"
              :show-upload-list="false"
              :on-format-error="handleFormatError"
              name="photo"
            >
              <template v-if="imgShow">
                <div class="card-img" style="margin-top: 10px;">
                  <img
                    :src="photoData.photo"
                    alt
                    width="80px"
                    height="80px"
                    title="点击更换头像"
                    v-if="photoData.photo"
                    style="cursor: pointer;"
                  />
                  <img
                    src="@/assets/img/bg_avatar.png"
                    alt
                    width="80px"
                    height="80px"
                    title="点击更换头像"
                    v-else
                    style="cursor: pointer;"
                  />
                </div>
              </template>
              <template v-else>
                <div class="card-img tac" style="margin-top: 10px;">
                  <div class="img-progress">
                    <Progress :percent="avatarShow.percentage" hide-info></Progress>
                  </div>
                </div>
              </template>
              <p class="card-text">头像</p>
            </Upload>
          </Col>
          <Col :lg="{ span: 2 }" :md="{ span: 4 }" class="tac" v-else>
            <div class="card-avatar">
              <div class="card-img">
                <img :src="avatar" alt width="80px" height="80px" v-if="avatar" />
                <img src="@/assets/img/bg_avatar.png" alt width="80px" height="80px" v-else />
              </div>
              <p class="card-text">头像</p>
            </div>
          </Col>
          <Col :lg="{ span: 22 }" :md="{ span: 20 }">
            <div class="card-info">
              <Form
                :label-width="100"
                :model="formData"
                :rules="ruleValidate"
                ref="formData"
              >
                <Row :gutter="16">
                  <Col :span="6">
                    <Form-item label="身份证号：">
                      <Input
                        placeholder="请输入..."
                        v-model="formData.id_card"
                        @on-blur="changeIdCard"
                        v-if="infoId"
                      ></Input>
                      <p v-else>{{ formData.id_card }}</p>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="姓名：" prop="name">
                      <Input
                        v-model="formData.name"
                        placeholder="请输入..."
                        @on-blur="changeName"
                        v-if="infoId"
                      ></Input>
                      <p v-else>{{ formData.name }}</p>
                    </Form-item>
                  </Col>
                  <Col :span="6" v-if="infoId">
                    <Form-item label="性别：" prop="sex">
                      <Select
                        placeholder="请选择"
                        class="w80"
                        v-model="formData.sex"
                        @on-change="changeGender"
                      >
                        <Option value="0">男</Option>
                        <Option value="1">女</Option>
                      </Select>
                    </Form-item>
                  </Col>
                  <Col :span="6" v-else>
                    <Form-item label="性别：" prop="sex">
                      <p>{{ formData.sex | sex }}</p>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="民族：" prop="nation">
                      <Select
                        placeholder="请选择"
                        v-model="formData.nation"
                        @on-change="changeNation"
                        v-if="infoId"
                      >
                        <Option v-for="item in nationList" :value="item" :key="item">{{ item }}</Option>
                      </Select>
                      <p v-else>{{ formData.nation }}</p>
                    </Form-item>
                  </Col>
                </Row>
                <Row :gutter="16">
                  <Col :span="6">
                    <Form-item label="生日：" prop="birthday">
                      <DatePicker
                        type="date"
                        v-model="birthday"
                        placeholder="选择日期"
                        @on-change="changeAge"
                        @on-clear="handleClear"
                        style="width: 100%;"
                        v-if="infoId"
                      ></DatePicker>
                      <p v-else>{{ formData.birthday }}</p>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Row>
                      <Col :span="6" style="margin-bottom: 0;">
                        <Form-item label="年龄：">{{ age }}</Form-item>
                      </Col>
                      <Col :span="14" style="margin-bottom: 0;">
                        <Form-item label="康龄：">
                          <DatePicker
                            type="date"
                            v-model="formData.jrrq"
                            placeholder="选择日期"
                            style="width: 100%;"
                            @on-change="changeJoinDate"
                            :options="dateOptions()"
                            v-if="infoId"
                          ></DatePicker>
                          <p v-else>{{ formData.jrrq }}</p>
                        </Form-item>
                      </Col>
                      <Col :span="4" style="text-align: center;line-height: 34px;margin-bottom: 0;">
                        <span v-if="formData.jrrq">{{getYearsDiff(formData.jrrq)}}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col :span="6">
                    <Form-item label="建档日期：">{{ create_time }}</Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="服务员工：">
                      <div style="display: flex;gap: 10px;" v-if="infoId">
                        <Select placeholder="请选择" v-model="staff">
                          <Option
                            :value="item.id"
                            v-for="(item, i) in staffs"
                            :key="i"
                          >{{ item.name }}</Option>
                        </Select>
                        <Button @click="changeStaff" type="primary">修改</Button>
                      </div>
                      <p v-else>{{ formData.staff }}</p>
                    </Form-item>
                  </Col>
                </Row>
                <Row :gutter="16">
                  <Col :span="6">
                    <Row>
                      <Col :span="12" style="margin-bottom: 0;">
                        <Form-item label="卡系：">
                          {{ kxmc }}
                        </Form-item>
                      </Col>
                      <Col :span="12" style="margin-bottom: 0;">
                        <Form-item label="状态：">
                          {{ kxzt }}
                        </Form-item>
                      </Col>
                    </Row>
                  </Col>
                  <Col :span="6">
                    <Form-item label="会员状态：">
                      <p style="display: inline-block;">{{ formData.hyzt }}</p>
                    </Form-item>
                  </Col>
                  <Col :span="6" v-if="jtxx">
                    <Form-item label="家庭信息：">
                      {{ jtxx }}
                    </Form-item>
                  </Col>
                </Row>
                <Row :gutter="16">
                  <Col :span="24">
                    <Form-item label="备注：">
                      <Input
                        placeholder="请输入..."
                        maxlength="30"
                        show-word-limit
                        v-model="formData.remark"
                        @on-blur="changeRemark"
                        v-if="infoId"
                      ></Input>
                      <p style="word-break: break-all" v-else>{{ formData.remark }}</p>
                    </Form-item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  </div>
</template>

<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import { sex } from '@/filters/filters';
import dayjs from 'dayjs';

export default {
	filters: { sex },
	data() {
		const validateName = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入姓名'));
			} else {
				let reg = /^[\u4E00-\u9FA5A-Za-z]+$/;
				if (!reg.test(value)) {
					callback(new Error('只能输入中文或者英文'));
				} else if (value.length > 10) {
					callback(new Error('姓名不能大于10个字符'));
				}
				callback();
			}
		};

		const validateDevice = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择设备使用情况'));
			} else {
				callback();
			}
		};
		const validateBirth = (rule, value, callback) => {
			if (this.birthday) {
				callback();
			} else {
				callback(new Error('请填写生日'));
			}
		};
		return {
			formData: {
				id_card: '',
				name: '',
				sex: 0,
				nation: '',
				device_type: '',
				remark: '',
				hyzt: '',
				staff: '',
				birthday: '',
				jrrq: '',
			},
			baseFormData: {},
			avatar: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			kxmc: '',
			kxzt: '',
			jtxx: '',
			photoData: {
				photo: '',
				id: '',
			},
			imgShow: true,
			avatarShow: '',
			cardEdit: false,
			nationList: [],
			statusList: [],
			baseData: {},
			ruleValidate: {
				birthday: [{ required: true, validator: validateBirth, trigger: 'change' }],
				name: [{ required: true, validator: validateName, trigger: 'blur' }],
				sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
				nation: [{ required: true, message: '请选择民族', trigger: 'change' }],
				device_type: [{ required: true, validator: validateDevice, trigger: 'change' }],
			},
			device_type: '',
			staffs: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		infoId() {
			return this.$route.params.id;
		},
	},
	created() {
		var param = { member_id: this.memberId };
		MemberService.getinfo(param).then((data) => {
			this.formData.device_type = this.device_type = data.device_type + '';
			this.photoData.photo = this.avatar = data.avatar;
			this.formData.id_card = data.id_card;
			this.formData.name = data.name;
			this.formData.sex = JSON.stringify(data.sex);
			this.formData.nation = data.nation;
			this.formData.remark = data.remark;
			this.formData.staff = data.staff;
			this.formData.birthday = data.birthday;
			this.birthday = data.birthday;
			this.formData.jrrq = data.jrrq;
			this.age = data.age;
			this.create_time = data.create_time;
			this.staff = data.staff_id;
			this.formData.hyzt = data.hyzt;
			this.kxmc = data.kxmc;
			this.kxzt = data.kxzt;
			this.jtxx = data.jtxx;
			sessionStorage.setItem('id_card', this.formData.id_card);
			sessionStorage.setItem('name', this.formData.name);
			sessionStorage.setItem('gender', this.formData.sex);
			sessionStorage.setItem('nation', this.formData.nation);
			sessionStorage.setItem('device_type', this.formData.device_type);
			sessionStorage.setItem('birthday', this.birthday);
			sessionStorage.setItem('jrrq', this.formData.jrrq);
			sessionStorage.setItem('remark', this.formData.remark);
			sessionStorage.setItem('hyzt', this.formData.hyzt);
			MemberService.getStaffList({ branch_id: data.branch_id, staff_id: data.staff_id }).then(
				(data) => {
					this.staffs = data;
				},
			);
		});
		this.getNationList();
	},
	methods: {
		changeAge(value) {
			this.birthday = value;
			var myDate = new Date();
			if (value) {
				this.age = myDate.getFullYear() - value.split('-')[0];
			}
			sessionStorage.setItem('birthday', this.birthday);
		},
		changeDevice(value) {
			this.device_type = this.formData.device_type = value;
			sessionStorage.setItem('device_type', this.formData.device_type);
		},
		handleClear() {
			this.age = '';
			this.birthday = '';
		},
		// 获取民族列表
		getNationList() {
			MemberService.getBase().then((data) => {
				this.nationList = data.nation;
				this.statusList = data.hyzt;
			});
		},
		handleUploadSuccess(response, file) {
			this.avatarShow = file;
			if (file.status === 'finished') {
				this.imgShow = true;
			} else {
				this.imgShow = false;
			}
			this.photoData.photo = this.avatar = response.data.photo;
			this.photoData.id = response.data.id;
			localStorage.setItem('head_id', this.photoData.id);
		},
		handleFormatError(file) {
			this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。');
			this.imgShow = true;
		},
		handleBeforeUpload() {
			this.imgShow = false;
		},
		changeIdCard() {
			sessionStorage.setItem('id_card', this.formData.id_card);
		},
		changeName() {
			sessionStorage.setItem('name', this.formData.name);
		},
		changeGender() {
			sessionStorage.setItem('gender', this.formData.sex);
		},
		changeRemark() {
			sessionStorage.setItem('remark', this.formData.remark);
		},
		changeNation() {
			sessionStorage.setItem('nation', this.formData.nation);
		},
		changeStatus() {
			sessionStorage.setItem('hyzt', this.formData.hyzt);
		},
		changeJoinDate(value) {
			this.formData.jrrq = value;
			sessionStorage.setItem('jrrq', this.formData.jrrq);
		},
		changeStaff() {
			MemberService.modifyMember({ staff_id: this.staff, member_id: this.memberId });
		},
		getYearsDiff(date) {
			return dayjs().diff(date, 'year') + '年';
		},
		dateOptions() {
			const self = this;
			return {
				disabledDate(date) {
					const value = date.valueOf();
					return date && (value > dayjs(self.create_time) || value < dayjs('2002-05-08'));
				},
			};
		},
	},
};
</script>

<style lang="css" scoped>
.card-container .ivu-card-head > p {
  color: #fff;
}
.card-container .card-box {
  position: relative;
}
.card-container .ivu-col {
  margin-bottom: 20px;
}
.card-container .card-avatar {
  padding: 10px 0;
}
.card-container .card-img img {
  border-radius: 100%;
  /*cursor: pointer;*/
}
.card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.card-container .card-info {
  padding-top: 30px;
}
.card-container .ivu-form-item {
  margin-bottom: 0;
}
.img-progress {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: inline-block;
}
</style>
